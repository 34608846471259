<template>
   <div class="page_section" v-if="line.bySlug.id">
      <div class="content_element abellio_world_element mb0">
         <div class=" job_offers_map_element">
            <GmapMap
               ref="mapRef"
               :center="{lat: currentLocation.lat, lng: currentLocation.lng}"
               :zoom="mapZoom"
               :options="mapOptions"
               class="job_offers_map_wrapper"
               :class="{ is_visible: showMap }">
               <GmapPolyline
                  v-for="(polyLineItem, polyLineIndex) in polyLines"
                  :key="polyLineIndex + 'lines_polyline'"
                  :path="polyLineItem.polyLines"
                  :options="{ strokeColor: polyLineItem.color}"/>
            </GmapMap>
         </div>
         <div class="d-flex justify-content-center flex-wrap abellio_world_filter_wrapper"
              :class="{is_open: !showAbellioWorldMobileContent}">

            <a href="#" class="abellio_world_filter_close"
               @click.prevent="showAbellioWorldMobileContent = !showAbellioWorldMobileContent"></a>
         </div>
         <div class="abellio_world_mobile_content_wrapper p-0" :class="{is_open: showAbellioWorldMobileContent}">
            <a href="#" class="abellio_world_filter_close_button"
               @click.prevent="showAbellioWorldMobileContent = false">
               <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.5"
                    height="21.5" viewBox="0 0 21.5 21.5">
                  <g id="Group_3" data-name="Group 3" transform="translate(0 0)" clip-path="url(#clip-path)">
                     <path id="Fill_1" data-name="Fill 1"
                           d="M10.75,21.5A10.75,10.75,0,1,1,21.5,10.75,10.762,10.762,0,0,1,10.75,21.5Zm0-9.69h0l4.416,4.419,1.061-1.061L11.811,10.75,16.23,6.33,15.169,5.27,10.751,9.689,6.331,5.27,5.27,6.33l4.42,4.42L5.27,15.169l1.06,1.061,4.42-4.419Z"
                           transform="translate(0 0)" fill="#c6c6c6"/>
                  </g>
               </svg>
            </a>
            <div class="abellio_world_mobile_content_inner p-0">
               <div class="container line-info pt-md-0 pt-4">
                  <Breadcrumb :links="breadcrumb" class="mb30"/>

                  <div class="row">
                     <div class="col-md-7">
                        <div class="heading">
                           <h1 class="title mb20" :style="{color: line.bySlug.color}">{{ line.bySlug.title }}</h1>
                        </div>
                     </div>
                     <div class="col-md-5 d-flex align-items-start justify-content-md-end">
                        <div class="types d-flex align-items-center">
                           <div class="a-type ml-0" :style="{ 'background-color' : line.bySlug.color }">
                              {{ line.bySlug.train.title }}
                              <span>Train</span>
                           </div>
                           <div class="a-type" :style="{ 'background-color' : line.bySlug.color }">
                              {{ line.bySlug.distance }} km
                              <span>Distance</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="mt25 mb25">
                  <StationsListSlider :stations="line.bySlug.stations" :title="'Stations'"/>
               </div>

               <div class="container mb30">
                  <div class="row">
                     <div class="col-12">
                        <h4 class=""></h4>
                     </div>
                     <div class="col-12 col-md-3 col-lg-2">
                        <h2>Verfügbare Downloads</h2>
                     </div>
                     <div class="col-12 col-md-9 col-lg-10 downloads-wrapper">
                        <div class="a-download" v-for="(station, index) in stationDocuments" :key="index">
                           <div class="inner d-flex align-items-center justify-content-between">
                              <div>
                                 <h6 class="title">{{ station.title }}</h6>
                                 <div class="info mb0">UMGEBUNGSPLÄNE</div>
                              </div>
                              <div class="info mb0 d-flex align-items-center">
                                 <div>
                                    <span class="text-muted">Download:</span>
                                    <strong class="text-primary ml5">PDF</strong>
                                 </div>
                                 <a :href="cmsAsset(station.env_map)" target="_blank" class="download-btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.2 32">
                                       <polygon
                                          points="30.2 21.3 30.2 32 0 32 0 21.3 1.8 21.3 1.8 30.2 28.4 30.2 28.4 21.3 30.2 21.3"></polygon>
                                       <polygon
                                          points="26.4 11.8 15.1 23.1 3.8 11.8 5.1 10.6 14.2 19.7 14.2 0 16 0 16 19.7 25.1 10.6 26.4 11.8"></polygon>
                                    </svg>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <Footer/>
      <a class="backend-edit" :href="backendUrl" v-if="backendAdminLoggedIn">Edit page</a>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import Footer from "../_common/Footer";
   import {gmapApi} from 'vue2-google-maps';
   import Breadcrumb from "../_common/Breadcrumb";
   import StationsListSlider from "../_common/StationsListSlider";

   export default {
      name: "Line",
      components: {
         Footer,
         Breadcrumb,
         StationsListSlider,
      },
      data() {
         return {
            showAbellioWorldMobileContent: true,
            mapOptions: {
               zoomControl: true,
               mapTypeControl: true,
               scaleControl: true,
               streetViewControl: true,
               rotateControl: true,
               fullscreenControl: true,
               disableDefaultUi: false
            },
            showMap: true,
            mapZoom: 5,
            currentLocation: {
               lat: 0,
               lng: 0
            },
            stationInfoWinOpen: 0,
         }
      },
      computed: {
         ...mapState([
            'line',
            'settings',
            'authentication'
         ]),
         google: gmapApi,
         stationDocuments() {
            let result = [];

            this.line.bySlug.stations.forEach(function (station) {
               if (station.env_map !== "") {
                  result.push(station)
               }
            });

            return result
         },
         polyLines() {
            let polylines = [];
            if (this.line.bySlug.map_line) {
               this.line.bySlug.map_line.forEach((polyLine) => {
                  let newPolyLine = [];
                  polyLine.forEach((point) => {
                     newPolyLine.push({
                        lat: parseFloat(point.latitude),
                        lng: parseFloat(point.longitude)
                     });
                  });
                  polylines.push({
                     line: this.line.bySlug.title,
                     color: this.line.bySlug.color,
                     polyLines: newPolyLine
                  });
               });
            }
            return polylines;
         },
         breadcrumb() {
            return [
               {
                  title: 'Abellio Netz',
                  path: '/abellio-netz',
                  active: false
               },
               {
                  title: 'Line: ' + this.line.bySlug.title,
                  path: this.$route.path,
                  active: true
               },
            ]
         },
         backendAdminLoggedIn() {
            return this.authentication.loggedIn && this.authentication.user.user.view_admin_link;
         },
         backendUrl() {
            return this.authentication.user.user.edit_links.line + '/' + this.line.bySlug.id;
         }
      },
      methods: {
         mapBound() {
            this.$nextTick(() => {
               if (this.$refs.mapRef && this.line.bySlug.map_line) {
                  this.$refs.mapRef.$mapPromise.then((map) => {
                     let mapBounds = new this.google.maps.LatLngBounds();
                     this.line.bySlug.map_line.forEach((polyLine) => {
                        polyLine.forEach((point) => {
                           mapBounds.extend({
                              lat: parseFloat(point.latitude),
                              lng: parseFloat(point.longitude)
                           });
                        });
                     });
                     map.fitBounds(mapBounds);
                  });
               }
            });
         },
         resize() {
            this.mapBound();
         },
      },
      created() {
         if (this.$route.params.slug) {
            this.$store.dispatch('line/getBySlug', this.$route.params.slug).then(() => {
            }, (error) => {
               if (error.response.status === 404) {
                  this.$router.replace({
                     name: '404', params: {
                        locale: this.$route.params.locale ? this.$route.params.locale : null
                     }
                  });
               }
            });
         }
      },
      mounted() {
         window.addEventListener('resize', this.resize);
         this.mapBound();
      },
      beforeMount() {
         this.mapOptions.styles = this.settings.all.map_style;
      },
      beforeDestroy() {
         this.$store.commit('job/setBySlug', {});
      },
      watch: {
         'line.bySlug': function () {
            this.mapBound();
         }
      }
   }
</script>

<style lang="scss" scoped>
   .job_map_wrapper {
      width: 100%;
      height: 350px;
   }
</style>
