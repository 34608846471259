<template>
   <div class="content_element carousel_content_element">
      <div class="container">
         <div class="row">
            <div class="col-12 col-sm-12 col-md-3 col-lg-2">
               <nl2br tag="h2" :text="title"/>
            </div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-10">
               <div class="carousel_content_wrapper">
                  <swiper
                     class="swiper"
                     ref="swiperCarousel"
                     :options="swiperOption"
                     :auto-update="true"
                     :auto-destroy="true"
                     v-if="stations && stations.length"
                  >
                     <swiper-slide
                        v-for="(item, index) in stations"
                        :key="index + 'carouselContent'"
                     >
                        <div
                           class="abellio_info_box"
                           :class="{ is_active: activeContentTitle == item.title }"
                        >
                           <div class="abellio_info_box_image"
                                :style="{backgroundImage: 'url(' + cmsAssetOrPlaceholder(item.image_thumb) + ')'}">
                           </div>
                           <div class="abellio_info_box_content">
                              <h6 class="abellio_info_box_title">{{ item.title }}</h6>
                              <p v-html="item.short_content"></p>
                           </div>
                           <a
                              href="#"
                              @click.prevent="openContent(item.title)"
                              class="abellio_info_box_more"
                           >Mehr erfahren</a
                           >
                        </div>
                     </swiper-slide>
                     <div class="swiper-pagination" slot="pagination"></div>
                  </swiper>
               </div>
            </div>
         </div>
      </div>

      <div class="carousel_big_content_wrapper" v-if="selectedContent && selectedContent.title">
         <div class="container">
            <div class="row">
               <div class="col-12 col-sm-12 col-lg-6">
                  <img
                     class="carousel_big_image"
                     :src="cmsAssetOrPlaceholder(selectedContent.image)"
                     :alt="selectedContent.title"
                  />
               </div>
               <div class="col-12 col-sm-12 col-lg-6 d-flex flex-column justify-content-between">
                  <div>
                     <h2>{{ selectedContent.title }}</h2>
                     <div class="html-content">
                        {{ selectedContent.street }} {{ selectedContent.zip }}
                     </div>
                  </div>
                  <div>

                     <div>
                        <router-link
                           :to="{ name: 'station', params: { slug: selectedContent.slug } }"
                           class="info_tag_btn more_btn d-inline-flex justify-content-between">
                         <span
                            class="color"
                            :style="{ backgroundColor: selectedContent.btn_color }"
                         ></span>
                           {{ selectedContent.btn_title }}
                           <span class="info_tag_arrow d-flex align-items-center">mehr</span>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import $ from "jquery";
   import {Swiper, SwiperSlide} from "vue-awesome-swiper";

   export default {
      name: "StationsListSlider",
      components: {
         Swiper,
         SwiperSlide,
      },
      props: {
         title: String,
         stations: {
            type: Array,
            required: true,
         },
      },
      data() {
         return {
            swiperOption: {
               slidesPerView: 1.5,
               spaceBetween: 16,
               breakpoints: {
                  // when window width is >= 320px
                  414: {
                     slidesPerView: 1,
                     spaceBetween: 16,
                  },
                  576: {
                     slidesPerView: 2.2,
                     spaceBetween: 16,
                  },
                  768: {
                     slidesPerView: 2.5,
                     spaceBetween: 16,
                  },
                  992: {
                     slidesPerView: 3.5,
                     spaceBetween: 16,
                  },
                  1280: {
                     slidesPerView: 3.5,
                     spaceBetween: 16,
                  },
               },
               pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
                  dynamicBullets: true
               },
            },
            activeContentTitle: null,
         };
      },
      computed: {
         selectedContent: function () {
            return this.stations.find((item) => {
               return item.title == this.activeContentTitle;
            });
         },
      },
      methods: {
         carouselWrapperWidth: function () {
            let element = $(this.$el).find(".carousel_content_wrapper");
            let offsetLeft = element.offset().left;
            element.outerWidth($(this.$el).outerWidth() - offsetLeft);

            this.$nextTick(() => {
               if (this.$refs.swiperCarousel) {
                  this.$refs.swiperCarousel.$swiper.update();
               }
            });
         },
         openContent(title) {
            this.activeContentTitle = title;
            this.$nextTick(() => {
               let el = document.querySelector('.carousel_big_content_wrapper');
               if (!this.isElementInViewport(el)) {
                  this.scrollToElement(el, true)
               }
            })
         },
      },
      mounted() {
         this.carouselWrapperWidth();
         window.addEventListener("resize", this.carouselWrapperWidth);
      },
   };
</script>

<style scoped>
   .swiper-slide {
      height: auto;
      padding: 5px 0 15px 0;
   }
</style>
